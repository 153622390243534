import { createStore } from 'vuex'
import user from './modules/user'

// 模块化状态管理
const store = createStore({
  modules: {
    user,
  },
})

export default store
