import {
    createRouter,
    createWebHashHistory
} from 'vue-router';

const routes = [
    {
        name: 'index',
        path: '/',
        component: () => import('@/views/index'),
        meta: {
            pageid: 3,
            title: '首页',
        },
    },
    {
        name: 'list',
        path: '/list',
        component: () => import('@/views/list'),
        meta: {
            pageid: 1,
            title: '列表',
        },
    },
]
const router = createRouter({
    history: createWebHashHistory(),
    routes,
})


export default router